import React from "react";

import Route from "./components/route";
import LandingPage from "./pages/landingpage";
import VizrPage from "./pages/vizr";
import LedgerLinkPage from "./pages/ledgerlink";

import './App.css';

function App() {

  return (
    <div className="App">
      <Route path="/">
        <LandingPage />
      </Route>
      <Route path="/vizr">
        <VizrPage />
      </Route>
      <Route path="/ledgerlink">
        <LedgerLinkPage />
      </Route>
    </div>
  );
}

export default App;