import React from "react";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import logo from '../images/logo_main.png';
import vizr from '../images/logo_sec.png';
import door from '../images/door.png';
import ctabg from '../images/cta_bg.png';

import twitter2 from '../images/icons/twitter.png';
import telegram from '../images/icons/telegram.png';

import bsc from '../images/partners/binancelogo.png';
import near from '../images/partners/nearlogo.png';
import immutable from '../images/partners/immutablelogo.png';
import sol from '../images/partners/solanalogo.png';
import stark from '../images/partners/starkwarelogo.png';
import avax from '../images/partners/avaxlogo.png';

import aalogo from '../images/partners/aalogo.svg';
import gotbit from '../images/partners/gotbit.png';
import zklogo from '../images/partners/zklogo.png';
import kucoin from '../images/partners/kucoin.png';
import kairon from '../images/partners/kairon.png';
import seedify from '../images/partners/seedify.png';
import meter from '../images/partners/meter.png';
import meterprev from '../images/projects/meter.png';
import ovlogo from '../images/partners/ovlogo.png';

import inspect from '../images/projects/nftinspect.png';
import inspectlogo from '../images/projects/nftinspectlogo.svg';
import blockgprev from '../images/projects/blockgamesprev.png';
import blockglogo from '../images/projects/blockglogo.png';
import blocki from '../images/projects/blocki.png';
import blockilogo from '../images/projects/blockilogo.png';
import pllogo from '../images/projects/pllogo.png';
import plpreview from '../images/projects/plpreview.png';

import '../App.css';

import '../fonts/pixel/Uni0553.woff';
import '../fonts/pixel/Uni0553.woff2';
import '../fonts/pixel/Uni0553.ttf';

import '../styles/theme.css'
import '../styles/mobile.css'



function LandingPage() {



    const div1 = React.useRef()
    const div2 = React.useRef()
    const div3 = React.useRef()
    const div4 = React.useRef()
    const div5 = React.useRef()

    const chains = React.useRef()
    const partner = React.useRef()
    /**
     * @param {HTMLElement} target 
     */
    function smoothScroll(target) {
        const { top } = target.getBoundingClientRect()
        window.scrollTo({
            top: top + window.pageYOffset,
            behavior: "smooth"
        });
    }

    (function () {

        var throttle = function (type, name, obj) {
            var obj = obj || window;
            var running = false;
            var func = function () {
                if (running) { return; }
                running = true;
                requestAnimationFrame(function () {
                    obj.dispatchEvent(new CustomEvent(name));
                    running = false;
                });
            };
            obj.addEventListener(type, func);
        };

        throttle("scroll", "optimizedScroll");
    })();

    window.addEventListener("optimizedScroll", function () {

        chains.current.style.transform = "translateX(-" + window.pageYOffset / 9 + "px)";
        partner.current.style.transform = "translateX(" + window.pageYOffset / 13 + "px)";

    })

    return (
        <div className="landing">
            <nav className="nav">
                <div>
                    <img src={logo} className="logo" alt="logo"
                        onClick={() => smoothScroll(div1.current)} />

                    <input className="nav-btn" type="checkbox" id="menu-btn" />
                    <label className="nav-icon" for="menu-btn"><span className="navicon"></span></label>
                    <div className='nav-items'>
                        <span className="button"
                            onClick={() => smoothScroll(div2.current)}>
                            Apply</span>
                        <span className="button"
                            onClick={() => smoothScroll(div3.current)}>
                            Thesis</span>
                        <span className="button"
                            onClick={() => smoothScroll(div4.current)}>
                            Cases</span>
                    </div>
                </div>
            </nav>


            <div className="intro" ref={div1}>
                <div className="container">
                    <div className="content-wrapper">
                        <h1>0 to 1 Digital Asset Acceleration</h1>
                        <a target={'_blank'} onClick={() => smoothScroll(div2.current)}>Discover</a>
                        <h5>Providing multi-layered guidance and resources to accelerate your growth</h5>
                    </div>
                </div>
                <div id="chains" ref={chains} className="partners">
                    <div className="image-wrapper">
                        <img className="immutable" src={avax} />
                    </div>
                    <div className="image-wrapper">
                        <img src={bsc} />
                    </div>
                    <div className="image-wrapper">
                        <img className="solstark" src={sol} />
                    </div>
                    <div className="image-wrapper">
                        <img className="near" src={near} />
                    </div>
                    <div className="image-wrapper">
                        <img className="immutable" src={immutable} />
                    </div>

                    <div className="image-wrapper">
                        <img className="solstark" src={stark} />
                    </div>
                    <div className="image-wrapper">
                        <img className="solstark" src={zklogo} />
                    </div>
                </div>

                <div id="partner" ref={partner} className="partners">

                    <div className="image-wrapper">
                        <img className="solstark" src={ovlogo} />
                    </div>
                    <div className="image-wrapper">
                        <img className="immutable" src={aalogo} />
                    </div>
                    <div className="image-wrapper">
                        <img className="solstark" src={gotbit} />
                    </div>
                    <div className="image-wrapper">
                        <img className="solstark" src={seedify} />
                    </div>
                    <div className="image-wrapper">
                        <img className="solstark" src={kucoin} />
                    </div>
                    <div className="image-wrapper">
                        <img src={kairon} />
                    </div>
                    <div className="image-wrapper">
                        <img className="solstark" src={meter} />
                    </div>


                </div>
            </div>
            <div className="apply" ref={div2} >
                <div className="content-wrapper">
                    <div className="vizr">
                        <div className="content">
                            <img src={vizr} />
                            <h4>Vizr is our acceleration program and studio solution for highly promising projects. Pass our rigid due diligence and fast-track your growth potential through our hands-on approach. We make sure your scaling process keeps running smoothly. </h4>


                        </div>
                        <div className="button-wrapper">
                            <a target={'_blank'} href='https://forms.gle/jzzUw8dHoga78Sr56'>Apply now</a>
                            <a className="outline" href='/vizr'>Read more</a>
                        </div>
                    </div>
                    <div className="lll">
                        <div className="content">
                            <img src={logo} />
                            <h4><br />Our dedicated network for strategically compatible partners. Our goal is to offer valuable insights and interesting opportunities to those who are closest in our network.<br /></h4>

                        </div>
                        <div className="button-wrapper">
                            <a target={'_blank'} href='https://forms.gle/gb3qm5WACt3Kjg4N8'>Partner now</a>
                            <a className="outline" href='/ledgerlink'>Read more</a>
                        </div>
                    </div>

                </div>
            </div>


            <div className="thesis" ref={div3}>
                <div className="door"><img src={door} /></div>
                <div className="container">
                    <div className="content-wrapper">
                        <h2>More than an accelerator </h2>

                        <p>Our team has experienced multiple cycles and understands the ins and outs of this emerging new asset class. We’ve been involved in each aspect of this nascent space, both on the project and investment side, and highly focus on providing value through bilateral hands-on execution. Staying on top of new trends is what sets us apart and what makes us a preferred advisor for highly promising or established projects as well as investment vehicles. </p>

                        <p>From a project side, we understand your needs and emotions, whether you are just getting started or are looking to scale your growth in a sustainable manner. We focus on targeting the right consumer demographics and making fundamental adjustments by diving into the trenches with you. From groundwork to high-level advisory, we got you covered right from the start. </p>

                        <p>From a partner side, we understand the value of a network effect. We’re all exploring a new digital era, in which being agile is the shortest route to success. By being in the war rooms with institutions, foundations and investment firms, we’ve developed strategies and theses that directly benefit those closest in our network. After all, many hands make light work and by combining powers, we pave the way for a long-term sustainable digital ecosystem, in which digital goods and assets are left, right and center.  </p>
                    </div>
                </div>
            </div>
            <div className="portfolio" ref={div4}>
                <div className="container">
                    <div className="content-wrapper">
                        <Tabs>
                            <div className="head">
                                <h2>Projects that preceded you.</h2>
                                <TabList className="tabs">
                                    <Tab>Infra</Tab>
                                    <Tab>NFTs</Tab>
                                    <Tab>DeFi</Tab>
                                    <Tab>Gaming</Tab>
                                    <Tab>SocialFi</Tab>
                                </TabList>
                            </div>
                            <TabPanel>
                                <div className="project">
                                    <div className="image-wrapper">
                                        <img height="80px" src={plpreview} />
                                    </div>
                                    <div className="content-wrapper">
                                        <img height="60px" src={pllogo} />
                                        <h6>Real Time Computing</h6>
                                        <p>A solid track record of building systems that scale. Our last launch attracted 2.1M Signups and 60M to our waitlist</p>
                                        <a target={'_blank'} href='https://primelab.io/'>Learn more</a>
                                    </div>

                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="project">
                                    <div className="image-wrapper">
                                        <img height="80px" src={inspect} />
                                    </div>
                                    <div className="content-wrapper">
                                        <img src={inspectlogo} />
                                        <h6>Social Intelligence</h6>
                                        <p>Connect with your NFT community, analyze community growth, see where influential people are moving, and more.</p>
                                        <a target={'_blank'} href='https://www.nftinspect.xyz/'>Learn more</a>
                                    </div>

                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="project">
                                    <div className="image-wrapper">
                                        <img src={meterprev} />
                                    </div>
                                    <div className="content-wrapper">
                                        <img height="60px" src={meter} />
                                        <h6>High-performance L2 Solution</h6>
                                        <p>Meter is an open source platform with Freedom and Fairness as the first principle.
                                            It is highly decentralized, censorship resistant yet blazing fast & MEV resistant. Its native metastable coin completes Satoshi’s vision of a sound money independent of the fiat system.</p>
                                        <a target={'_blank'} href='https://meter.io/'>Learn more</a>
                                    </div>

                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="project">
                                    <div className="image-wrapper">
                                        <img src={blockgprev} />
                                    </div>
                                    <div className="content-wrapper">
                                        <img height="30px" src={blockglogo} />
                                        <h6>Bringing benefits of web3 to mobile games</h6>
                                        <p>BlockGames is a web3 mobile game publisher.</p><p>We enable digital ownership for the gaming communities through Free-to-Play & Play-and-Own mobile games.</p>
                                        <a target={'_blank'} href='https://www.block-games.co/'>Learn more</a>
                                    </div>

                                </div>

                            </TabPanel>
                            <TabPanel>
                                <div className="project">
                                    <div className="image-wrapper">
                                        <img src={blocki} />
                                    </div>
                                    <div className="content-wrapper">
                                        <img height="30px" src={blockilogo} />
                                        <h6>Social finance</h6>
                                        <p>Blockify’s mission is to be the heartbeat of SocialFi. Unlock the potential of Web3 for everyone.</p>
                                        <a target={'_blank'} href='https://blockify.com/'>Learn more</a>
                                    </div>

                                </div>

                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
            <div className="cta" ref={div5}>
                <div className="ctabg"><img src={ctabg} /></div>
                <div className="container">
                    <div className="content-wrapper">
                        <h4>Your goal, Our mission.<br /><br />
                            We tailor our services to your needs and focus on the sustainable growth of the digital ecosystem by being agile and staying on top of new market developments
                        </h4>

                        <a target={'_blank'} href='https://calendly.com/nicolaslll'>Schedule a call</a>
                    </div>
                </div>
            </div>

            <footer>
                <div className="info">
                    <img src={logo} className="logo" alt="logo"
                        onClick={() => smoothScroll(div1.current)} />
                    <div className="socials">
                        <a target={'_blank'} href='https://twitter.com/Ledgerlinklabs'><img src={twitter2} /></a>
                        <a target={'_blank'} href='https://t.me/NicolasLLL'><img src={telegram} /></a>
                    </div>
                    <strong>&copy; 2023 LedgerLink Labs. All rights reserved.</strong>
                </div>
                <div className="links">
                    <strong>Get in touch</strong>
                    <a target={'_blank'} href='https://forms.gle/jzzUw8dHoga78Sr56'>Apply as a Project</a>
                    <a target={'_blank'} href='https://forms.gle/gb3qm5WACt3Kjg4N8'>Apply as a Partner</a>
                </div>

            </footer>
        </div>

    );
}


export default LandingPage;