import React from "react";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import logo from '../images/logo_main.png';
import vizr from '../images/logo_sec.png';
import ctabg from '../images/cta_bg.png';

import meter from '../images/partners/meter.png';
import meterprev from '../images/projects/meter.png';
import inspect from '../images/projects/nftinspect.png';
import inspectlogo from '../images/projects/nftinspectlogo.svg';
import blockgprev from '../images/projects/blockgamesprev.png';
import blockglogo from '../images/projects/blockglogo.png';
import blocki from '../images/projects/blocki.png';
import blockilogo from '../images/projects/blockilogo.png';
import tinyprev from '../images/projects/tinyprev.png';
import tinyverse from '../images/projects/tinyverse.svg';
import awprev from '../images/projects/awprev.png';
import awlogo from '../images/projects/awlogo.webp';
import pllogo from '../images/projects/pllogo.png';
import plpreview from '../images/projects/plpreview.png';

import twitter2 from '../images/icons/twitter.png';
import telegram from '../images/icons/telegram.png';
import '../App.css';

import '../fonts/pixel/Uni0553.woff';
import '../fonts/pixel/Uni0553.woff2';
import '../fonts/pixel/Uni0553.ttf';

import '../styles/theme.css'
import '../styles/mobile.css'



function VizrPage() {

    const div1 = React.useRef()
    const div2 = React.useRef()
    const div3 = React.useRef()
    const div4 = React.useRef()

    /**
     * @param {HTMLElement} target 
     */
    function smoothScroll(target) {
        const { top } = target.getBoundingClientRect()
        window.scrollTo({
            top: top + window.pageYOffset,
            behavior: "smooth"
        });
    }

    return (
        <div className="App">
            <nav className="nav">
                <div>
                    <img src={logo} className="logo" alt="logo"
                        onClick={() => smoothScroll(div1.current)} />

                    <input className="nav-btn" type="checkbox" id="menu-btn" />
                    <label className="nav-icon" for="menu-btn"><span className="navicon"></span></label>
                    <div className='nav-items'>
                        <a className="outline" href='/'>Home</a>
                        <span className="button"
                            onClick={() => smoothScroll(div1.current)}>
                            About</span>
                        <span className="button"
                            onClick={() => smoothScroll(div2.current)}>
                            Portfolio</span>
                        <span className="button"
                            onClick={() => smoothScroll(div3.current)}>
                            Programs</span>
                    </div>
                </div>
            </nav>


            <div className="intro" ref={div1}>
                <div className="container">
                    <div className="content-wrapper">
                        <img src={vizr} />
                        <h3>Providing multi-layered guidance and resources to accelerate your growth  </h3>
                        <p>Vizr is our acceleration program and studio solution for highly promising projects. Pass our rigid due diligence and fast-track your growth potential through our hands-on approach. We make sure your scaling process keeps running smoothly.  </p>
                        <p>We take pride in taking our time to get to know your project as we want to deploy effective solutions and maximize the mutually beneficial value. Especially with the current market environment, we stand by our motto of being a boutique solution and not a factory. We actively look for long-term value, not short-term numbers, as we fundamentally believe in the technology behind the projects. </p>
                        <p>Once we’ve had our first dance during the initial due diligence period and we both conclude that we can provide mutually beneficial value, we look for the right acceleration track or tailor a special service package, in which we both feel that we could thrive. After all everyone’s needs are different, but the goals are the same, namely driving adoption and buy-side liquidity. </p>
                        <p>Once we’ve identified the right track, we join your journey of scaling your solutions and try to deliver as much value as possible. After all, your success is our metric. </p>
                        <a target={'_blank'} href='https://forms.gle/jzzUw8dHoga78Sr56'>Apply now</a>
                    </div>
                </div>

            </div>
            <div className="portfolio" ref={div2}>
                <div className="container">
                    <div className="content-wrapper">
                        <Tabs>
                            <div className="head">
                                <h2>Portfolio.</h2>
                                <TabList className="tabs">
                                    <Tab>Infra</Tab>
                                    <Tab>Gaming</Tab>
                                    <Tab>NFTs</Tab>
                                    <Tab>Gaming</Tab>
                                    <Tab>DeFi</Tab>
                                    <Tab>Infra</Tab>
                                    <Tab>SocialFi</Tab>
                                </TabList>
                            </div>
                            <TabPanel>
                                <div className="project">
                                    <div className="image-wrapper">
                                        <img height="80px" src={plpreview} />
                                    </div>
                                    <div className="content-wrapper">
                                        <img height="60px" src={pllogo} />
                                        <h6>Real Time Computing</h6>
                                        <p>A solid track record of building systems that scale. Our last launch attracted 2.1M Signups and 60M to our waitlist</p>
                                        <a target={'_blank'} href='https://primelab.io/'>Learn more</a>
                                    </div>

                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="project">
                                    <div className="image-wrapper">
                                        <img height="80px" src={tinyprev} />
                                    </div>
                                    <div className="content-wrapper">
                                        <img src={tinyverse} />
                                        <h6>Biggest Gaming Sale on Fractal</h6>
                                        <p>A pixelated realm of highly advanced, humanoid ants fighting for survival and the growth of their colony, fantastical creatures and lore filled with epic tales.</p>
                                        <a target={'_blank'} href='https://tinycolony.io/'>Learn more</a>
                                    </div>

                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="project">
                                    <div className="image-wrapper">
                                        <img height="80px" src={inspect} />
                                    </div>
                                    <div className="content-wrapper">
                                        <img src={inspectlogo} />
                                        <h6>Social Intelligence</h6>
                                        <p>Connect with your NFT community, analyze community growth, see where influential people are moving, and more.</p>
                                        <a target={'_blank'} href='https://www.nftinspect.xyz/'>Learn more</a>
                                    </div>

                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="project">
                                    <div className="image-wrapper">
                                        <img height="40px" src={awprev} />
                                    </div>
                                    <div className="content-wrapper">
                                        <img src={awlogo} />
                                        <h6>Souls-like Game</h6>
                                        <p>Abyss World is a third-person ARPG game. Players will explore the traces of the "old tide" in the vast Nordinian world and uncover the secrets behind it.</p>
                                        <a target={'_blank'} href='https://www.abyssworld.games/'>Learn more</a>
                                    </div>

                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="project">
                                    <div className="image-wrapper">
                                        <img src={meterprev} />
                                    </div>
                                    <div className="content-wrapper">
                                        <img height="60px" src={meter} />
                                        <h6>High-performance L2 Solution</h6>
                                        <p>Meter is an open source platform with Freedom and Fairness as the first principle.
                                            It is highly decentralized, censorship resistant yet blazing fast & MEV resistant. Its native metastable coin completes Satoshi’s vision of a sound money independent of the fiat system.</p>
                                        <a target={'_blank'} href='https://meter.io/'>Learn more</a>
                                    </div>

                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="project">
                                    <div className="image-wrapper">
                                        <img src={blockgprev} />
                                    </div>
                                    <div className="content-wrapper">
                                        <img height="30px" src={blockglogo} />
                                        <h6>Bringing benefits of web3 to mobile games</h6>
                                        <p>BlockGames is a web3 mobile game publisher.</p><p>We enable digital ownership for the gaming communities through Free-to-Play & Play-and-Own mobile games.</p>
                                        <a target={'_blank'} href='https://www.block-games.co/'>Learn more</a>
                                    </div>

                                </div>

                            </TabPanel>
                            <TabPanel>
                                <div className="project">
                                    <div className="image-wrapper">
                                        <img src={blocki} />
                                    </div>
                                    <div className="content-wrapper">
                                        <img height="30px" src={blockilogo} />
                                        <h6>Social finance</h6>
                                        <p>Blockify’s mission is to be the heartbeat of SocialFi. Unlock the potential of Web3 for everyone.</p>
                                        <a target={'_blank'} href='https://blockify.com/'>Learn more</a>
                                    </div>

                                </div>

                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
            <div className="usp" ref={div3} >
                <div className="container">
                    <div className="content-wrapper">
                        <div className="title"><h3>Acceleration Programs</h3></div>
                        <div className="usp-wrapper">
                            <div className="content">
                                <h4>Analysis</h4>
                                <p>Our short-term acceleration program, where we identify your current and future bottlenecks and provide high-level solutions. Furthermore, we source our partner network to help you grow your business on multiple levels. </p>
                                <h5>200+</h5>
                                <h6>Partners in our network</h6>
                            </div>
                            <div className="content">
                                <h4>Representation</h4>
                                <p>Our in-depth acceleration program, where we identify current and future bottlenecks and provide hands-on assistance to resolve any emerging problems. </p><p>We actively engage with our partner network and target other suitable actors to rapidly reach your goals.  </p>
                            </div>
                        </div>
                        <div className="usp-wrapper usp-secondary">
                            <div className="content">
                                <h4>Portfolio</h4>
                                <p>Our dedicated acceleration program, where we become a part of your team to actively engage and tackle any emerging problems. We dive into the trenches with you and actively search for the right partners to reach your full potential as soon as possible. </p>
                                <h5>50+</h5>
                                <h6>Clients around the world</h6>

                            </div>
                            <div className="content">
                                <h4>Resources</h4>
                                <p>Our strengths lie within building the right tools for your project to make it succeed. Whether you need ideas to increase the utility of your protocol, actual development capacity or business development scaling, we got you covered. </p><p>Reach out and we are open to discussing a tailored pick-and-shuffle approach.  </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="cta" ref={div4}>
                <div className="ctabg"><img src={ctabg} /></div>
                <div className="container">
                    <div className="content-wrapper">
                        <h4>Your goal, Our mission.<br /><br />
                            We tailor our services to your needs and focus on the positioning and sustainable growth of your project
                        </h4>

                        <a target={'_blank'} href='https://calendly.com/nicolaslll'>Schedule a call</a>
                    </div>
                </div>
            </div>

            <footer>
                <div className="info">
                    <img src={logo} className="logo" alt="logo"
                        onClick={() => smoothScroll(div1.current)} />
                    <div className="socials">
                        <a target={'_blank'} href='https://twitter.com/Ledgerlinklabs'><img src={twitter2} /></a>
                        <a target={'_blank'} href='https://t.me/NicolasLLL'><img src={telegram} /></a>
                    </div>
                    <strong>&copy; 2023 LedgerLink Labs. All rights reserved.</strong>
                </div>
                <div className="links">
                    <strong>Get in touch</strong>
                    <a target={'_blank'} href='https://forms.gle/jzzUw8dHoga78Sr56'>Apply as a Project</a>
                    <a target={'_blank'} href='https://forms.gle/gb3qm5WACt3Kjg4N8'>Apply as a Partner</a>
                </div>

            </footer>
        </div>

    );
}


export default VizrPage;