import { useContext } from "react";
import NavContext from "./nav";

function Route({ path, children }) {
    const { currentPath } = useContext(NavContext);

    if (path === currentPath) {
        return children;
    }

    return null;
}

export default Route;